<template>
  <main class="view">
    <div class="col col-12 tabs-wrapper">
      <ul :class="['tab-controller', {hidden: !showTabs}]" style="--submenu-bg: #1B577A;">
        <li :class="['tab-controller__item', {active: listsView == 0, '--parent': Check(AdminRoles)}]">
          <router-link class="item-link" :to="{name: 'orders'}">{{ $t('Orders') }}</router-link>
          <ul class="item-submenu">
            <!--
            <li class="item-submenu__item">
              <router-link class="submenu-link" :to="{ name: 'lists-overview'}"> Overview </router-link>
            </li>
            <li class="item-submenu__item">
              <router-link class="submenu-link" :to="{ name: 'lists-approve'}"> Approve </router-link>
            </li>
            -->
          </ul>
        </li>
        <li v-if="Check(AdminRoles)" :class="['tab-controller__item', {active: listsView == 1, '--parent': Check(AdminRoles)}]">
          <router-link class="item-link" :to="{name: 'shipments'}">{{ $t('Shipments') }}</router-link>
          <ul class="item-submenu"></ul>
        </li>
        <li v-if="Check(['admin', 'customer_admin', 'customer'])" :class="['tab-controller__item', {active: listsView == 2, '--parent': Check(['admin', 'customer_admin', 'customer'])}]">
          <router-link class="item-link" :to="{name: 'customers'}">{{ $t('Customers') }}</router-link>
          <ul class="item-submenu"></ul>
        </li>
        <li v-if="Check(AdminRoles)" :class="['tab-controller__item', {active: listsView == 3, '--parent': Check(AdminRoles)}]">
          <router-link class="item-link" :to="{name: 'messages'}">{{ $t('Messages') }}</router-link>
          <ul class="item-submenu"></ul>
        </li>
        <li v-if="Check(AdminRoles)" :class="['tab-controller__item', {active: listsView == 4, '--parent': Check(AdminRoles)}]">
          <router-link class="item-link" :to="{name: 'bypass'}">{{ $t('Bypass order') }}</router-link>
          <ul class="item-submenu"></ul>
        </li>
      </ul>
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
  import { Permissions } from '@/helpers/Permissions';
  import { Config } from '@/helpers/Config';
  import { BPA } from '@/helpers/BPA';

  export default {
    name: 'Parcels',
    mixins: [Permissions, Config, BPA],
    data() {
      return {
        dev: Config.dev,
        showTabs: false,
        listsView: 0,
        RouteMapper: {
          orders: 0,
          shipments: 1,
          customers: 2,
          messages: 3,
          bypass: 4
        },
        AdminRoles: [/*0, 7*/ 'admin', 'parcels_admin'],
        route: null
      }
    },
    created() {
      this.IsInRouteMapper();
    },
    mounted() {
      this.showTabs = this.tabs.length > 1;
    },
    watch: {
      $route() {
        this.IsInRouteMapper();
      }
    },
    computed: {
      tabs() {
        return document.querySelectorAll('.tab-controller__item');
      }
    },
    methods: {
      IsInRouteMapper() {
        this.route = this.$route.name;
        let name = this.route.split('-')[0];
        if (this.RouteMapper[name] !== null) {
          this.listsView = this.RouteMapper[name];
        }
      },
      Check(required) {
        return BPA.permissions(required).length;
      },
      IsSuperUser() {
        return this.Check([/*0*/ 'admin']);
      },
      IsMainCompany() {
        return BPA.util.IsMainCompany();
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
